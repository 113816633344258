<template>
  <div class="containt-training">
    <div class="training row col-12 q-mt-lg">
      <div class="col-12">
        <div class="card-training row col-12">
          <div class="col-md-4 col-sm-12">
            <q-img :src="image" class="image-training" height="120px">
              <div
                class="absolute-bottom-left section-button-images"
                v-show="available"
              >
                <RoundedBtn
                  titleButton="Continuar"
                  :onClickBtn="accessTraining"
                />
              </div>
              <div
                class="absolute-bottom-left section-button-images"
                v-show="participate"
                @click="participateTraining"
              >
                <RoundedBtn styleButton="orange" titleButton="Participar" />
              </div>
              <div
                class="absolute-bottom-left section-button-images"
                v-show="finish"
              >
                <RoundedBtn
                  titleButton="Concluído"
                  route="training"
                  :query="{
                    courseId: training.course_id,
                    trainingId: training.id,
                    postId: training.post_id,
                  }"
                  styleButton="green"
                />
              </div>
            </q-img>
          </div>
          <div class="description-training col-md-8 col-sm-12 q-pt-sm">
            <div class="header-description-training row flex col-12 q-pl-sm">
              <div class="header-icon flex items-center col-8">
                <div class="q-mr-sm">
                  <Badge iconBadge="book" typeBadge="Conhecimento" />
                </div>
                <label>{{ training.training_title }}</label>
              </div>
              <div
                class="btn-header-edition flex justify-end col-4 q-gutter-sm"
              >
                <q-btn
                  v-if="btnEditTraning"
                  round
                  dense
                  size="md"
                  color="default-pink"
                  :to="{
                    name: 'new-training',
                    query: {
                      courseId: training.course_id,
                      trainingId: training.id,
                    },
                  }"
                >
                  <q-img width="70%" :src="editImg" spinner-color="white" />
                </q-btn>
                <q-btn
                  v-if="btnEditTraning"
                  round
                  dense
                  icon="delete"
                  size="md"
                  color="default-pink"
                  @click="handleDeleteTraining(training)"
                >
                </q-btn>
              </div>
            </div>
            <div class="body-description-training">
              <div class="list-info col-12 row">
                <div class="col-6 icon-info-trilha q-py-sm row items-center">
                  <Clock
                    color="var(--q-secundary)"
                    class="q-mr-md"
                    width="1.5rem"
                    height="1.5rem"
                  />
                  <label>{{ training.workload_hour }} horas</label>
                </div>
                <div class="col-6 flex items-center icon-info-trilha">
                  <Teacher class="q-mr-sm" color="var(--q-secundary)" />
                  <label>{{
                    training.first_name + " " + training.last_name
                  }}</label>
                </div>
                <div class="col-6 icon-info-trilha"></div>
                <div class="col-6 icon-info-trilha"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import trainingImg from "@/assets/icons/training-icon.svg";
import editImg from "@/assets/icons/edit.svg";
import clockImg from "@/assets/icons/clock.svg";
import chalkboardImg from "@/assets/icons/chalkboard-teacher.svg";
import folderImg from "@/assets/icons/folder-tree.svg";
import multipleImg from "@/assets/icons/multiple-user.svg";

import Teacher from "@/assets/icons/custom/Teacher.vue";
import Clock from "@/assets/icons/custom/Clock.vue";

import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import Badge from "@/components/BadgeType.vue";

import { date, useQuasar } from "quasar";
import http from "@/http-common";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

import TrailDataServices from "@/services/TrailDataServices";

export default {
  name: "TutorNotificationCard",
  components: {
    RoundedBtn,
    Badge,
    Teacher,
    Clock,
  },
  props: {
    training: Object,
    image: String,
  },
  setup(props) {
    /* CONSTANTS  */
    const trailDataServices = new TrailDataServices();
    const user = JSON.parse(localStorage.getItem("vuex")).userdata;

    /* VARIABLES */
    let $q = new useQuasar();

    let router = new useRouter();
    let loading = ref(false);
    let participate =
      !props.training.status_user && props.training.training_finish == null;
    let available =
      props.training.status_user && props.training.training_finish == null;
    let finish =
      props.training.status_user && props.training.training_finish != null;
    let canUserAccessClass = new CanUserAccessClass();
    let btnEditTraning = ref(true);

    onMounted(() => {
      ValidateActionsPage();
    });

    /* FUNCTIONS */

    async function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("edit-training", false)) {
        btnEditTraning.value = false;
        return;
      }
    }

    function accessTraining() {
      const { id, end_date, post_id, course_id, can_access } = props.training;
      const validatePerfil = [1, 2].includes(user.role_id);
      const formattedEnd = date.formatDate(end_date, "DD/MM/YYYY");

      if (!validatePerfil && !can_access) {
        $q.notify({
          message: `Esse treinamento só pode ser acessado até a data limite $ ${formattedEnd}`,
          type: "warning",
          position: "top",
          timeout: 4000,
        });
        return;
      } else if (!can_access) {
        $q.notify({
          message: `Esse treinamento só pode ser acessado até a data limite ${formattedEnd}`,
          type: "warning",
          position: "top",
          timeout: 4000,
        });
      }

      router.push({
        name: "training-page",
        query: {
          courseId: course_id,
          trainingId: id,
          postId: post_id,
        },
      });
    }

    async function participateTraining() {
      loading.value = true;

      const { id, end_date, post_id, course_id, can_access, tutor_id } =
        props.training;
      const validatePerfil = [1, 2].includes(user.role_id);
      const formattedEnd = date.formatDate(end_date, "DD/MM/YYYY");

      if (!validatePerfil && !can_access) {
        $q.notify({
          message: `Você só pode pedir para participar desse treinamento até a data limite ${formattedEnd}`,
          type: "warning",
          position: "top",
          timeout: 4000,
        });
        loading.value = false;
        return;
      } else if (!can_access) {
        $q.notify({
          message: `Você só pode pedir para participar desse treinamento até a data limite ${formattedEnd}`,
          type: "warning",
          position: "top",
          timeout: 4000,
        });
      }

      await http
        .post(`v1/course/${course_id}/training/${id}/enter-as-student`)
        .then((response) => {
          loading.value = false;
          router.push({
            name: "training-page",
            query: {
              courseId: course_id,
              trainingId: id,
              postId: post_id,
              tutorId: tutor_id,
            },
          });
        })
        .catch((error) => {
          console.error(error);
          _alertOf(error.response);
          loading.value = false;
        });
    }

    function handleDeleteTraining(training) {
      const { id, training_title, course_id } = training;
      $q.notify({
        message: `Você deseja excluir o treinamento <strong>${training_title}</strong>?`,
        color: "red-9",
        html: true,
        position: "top",
        actions: [
          {
            label: "Sim",
            color: "yellow",
            handler: async () => {
              await trailDataServices
                .deleteTraining(course_id, id)
                .then((response) => {
                  if (response.data) {
                    $q.notify({
                      message: `O treinamento <strong>${training_title}</strong> foi excluído com sucesso`,
                      html: true,
                      color: "green-7",
                      position: "top",
                    });
                  }
                  router.go();
                })
                .catch((error) => {
                  $q.notify({
                    message: `Erro ao tentar excluir o treinamento <strong>${training_title}</strong>`,
                    color: "red-9",
                    position: "top",
                  });
                });
            },
          },
          {
            label: "Não",
            color: "white",
          },
        ],
      });
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }
    return {
      //----IMAGES
      trainingImg,
      editImg,
      clockImg,
      chalkboardImg,
      folderImg,
      multipleImg,

      //------------
      loading,
      participate,
      btnEditTraning,
      available,
      finish,
      participateTraining,
      /* FUNCTIONS */
      handleDeleteTraining,
      accessTraining,
    };
  },
};
</script>

<style lang="scss" scoped>
.containt-training {
  border-radius: 20px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
}
.training {
  padding: 1%;
  background: #fdfdfd;
  border-radius: 24px;

  .card-training {
    flex-direction: column;
    @media (min-width: 800px) {
      flex-direction: row;
    }
  }
}

.image-training {
  border-radius: 10px;
}

.section-button-images {
  border-radius: 20px;
  padding: 3px !important;
  margin: 0 0 1% 1%;
  width: 140px;

  background: none;

  /* .button-image {
    background-color: #ffffff;
  } */
}

.participate {
  background-color: #fa6b1f;

  .button-image {
    color: #fa6b1f;
  }
}
.continue {
  background-color: var(--q-primary, #fe4e64);
  .button-image {
    color: var(--q-primary, #fe4e64);
  }
}
.finish {
  background-color: #7cae44;
  .button-image {
    color: #7cae44;
  }
}

.description-training {
  .header-description-training {
    .btn-header-edition {
      height: 33%;
    }
    .header-icon {
      & > div {
        width: 45px;
        height: 45px;
      }

      label {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
    }
  }

  .body-description-training {
    padding: 2%;
  }
}

.image-training {
  img {
    border-radius: 24px;
  }
}
</style>
