<template>
  <div class="main-content q-pa-md">
    <Mural
      :notificationQuantity="training_count"
      :breadcrumbs="breadcrumbs"
      route="knowledge"
      :query="{}"
    >
      <DefaultBtn
        v-show="btnAddTraining"
        titleButton="Novo Treinamento"
        :query="{ courseId: courseId }"
        route="new-training"
      />
    </Mural>
    <div class="list-training q-mt-md">
      <TrainingCard
        :key="training.id"
        v-for="training in trainings"
        :training="training"
        :image="url_aws_bucket + training.cover_image"
      />
    </div>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import TrainingCard from "@/components/courses/cards/TrainingCard.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";

import TrailDataServices from "@/services/TrailDataServices";
import BreadcrumbsDataServices from "@/services/BreadcrumbsDataServices";

import { ENV } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";

export default {
  name: "training",
  components: {
    Mural,
    TrainingCard,
    DefaultBtn,
  },
  setup() {
    let route = new useRoute();
    let breadcrumbs = ref([]);

    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let trailDataServices = new TrailDataServices();
    let breadcrumbsDataServices = new BreadcrumbsDataServices();

    let trainings = ref([]);
    let training_count = ref(0);
    let courseId = route.query.id;
    let btnAddTraining = ref(true);
    let canUserAccessClass = new CanUserAccessClass();

    onMounted(() => {
      ValidateActionsPage();
      getAllTraining(route.query.id);
      GetBreadcrumbsTraining(route.query.id);
    });

    async function getAllTraining(courseId) {
      let data = await trailDataServices.getAllTraining(courseId);

      trainings.value = data.data;
      training_count.value = data.data.length;
    }

    async function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("new-training", false)) {
        btnAddTraining.value = false;
        return;
      }
    }

    async function GetBreadcrumbsTraining(courseId) {
      let result = await breadcrumbsDataServices.GetBreadcrumbsTraining(
        courseId
      );
      breadcrumbs.value = ["Trilha", [result.data]];
    }

    return {
      trainings,
      courseId,
      btnAddTraining,
      training_count,
      url_aws_bucket,
      breadcrumbs,
      getAllTraining,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .list-training {
    overflow: auto;
    height: 75vh;
    padding: 1%;
  }

  hr {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    border: none;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
